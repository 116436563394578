import { observer } from "mobx-react";
import Stars from "../../components/stars";
import { getGrades } from "../../utils/request";
import styles from "../../css/userGrade.module.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";

function UserGrade() {
  const [books, setBooks] = useState([]);
  const [total, setTotal] = useState(0);
  const [average, setAverage] = useState(0);
  const { setHead } = useContext(LayoutContext);

  const getGradesData = useCallback(() => {
    getGrades().then((res) => {
      setBooks(res.data.books);
      setTotal(res.data.total);
      setAverage(res.data.average);
    });
  }, []);

  useEffect(() => {
    getGradesData();
    setHead({
      needMenu: true,
    });
  }, [setHead, getGradesData]);

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.pageTitle}>
          <span>已完成书籍{books.length}本</span>
          <span>我的总分{total}</span>
          <span>平均分{average}</span>
        </div>
        <div className={styles.books}>
          {books.map(({ book, id, rating, vote, reaction }) => (
            <div className={styles.bookItem} key={id}>
              <div className={styles.bookCover}>
                <img src={book.cover} alt="cover"></img>
              </div>
              <div className={styles.itemInfo}>
                <p className={styles.itemName}>《{book.title}》</p>
                <p>答题得分: {rating}</p>
                <p>读后感得分: {reaction?.rating}</p>
                <p>总分: {rating + (reaction?.rating || 0)}</p>
                <Stars
                  value={reaction?.mark}
                  count={reaction?.mark}
                  disabled
                ></Stars>
                <p className={styles.itemVote}>票数：{vote}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default observer(UserGrade);

import axios from "axios";
import { message } from "antd";
import userStore from "../store/userStore";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (response.data) {
      if (response.data && response.data.code === 401) {
        console.log("未登录");
        localStorage.removeItem("token");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
        // TODO: redirect to login page
        // throw new Response("", { status: 401 });
        // message.error(response.data.msg)
        return Promise.reject("未登录");
      }
      if (response.data.code && response.data.code !== 0) {
        console.log("后台报错", response);
        message.error(response.data.msg);
        return Promise.reject(response.data.msg);
      }
      if (response.data && response.data.data && response.data.data.addCoin) {
        message.open({
          type: "success",
          content: `Congratulations! You have earned ${response.data.data.addCoin} more A-coins!`,
        });
      }
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.status === 401) {
      console.log("未登录");
      localStorage.removeItem("token");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      // TODO: redirect to login page
      // throw new Response("", { status: 401 });
      // redirect('login')
    }
    return Promise.reject(error);
  }
);

// userStore.refresh()

export async function userLogin(data) {
  const result = await axios.post("/v1/auth/login", data);
  if (result.code === 0) {
    // 登录成功
    localStorage.setItem("token", result.data.access_token);
    const user = await userStore.refresh();
    return user;
  }
  return result;
}

export function getUserIssues() {
  return axios.get("/v1/issue/getUserIssues");
}

export function getIssueCountries(data) {
  return axios.post("/v1/country/getIssueCountries", data);
}

export function getCountryIdeas(data) {
  return axios.post("/v1/idea/getCountryIdeas", data);
}

export function cancelLike(data) {
  return axios.post("/v1/like/cancelLike", data);
}

export function getTestProfiles() {
  return axios.get("/v1/test/getTestProfiles");
}

export function getTestAnswers(data) {
  return axios.post("/v1/test/getTestAnswers", data);
}

export function getTestQuestions(data) {
  return axios.post("/v1/test/getTestQuestions", data);
}

export async function submitTestAnswer(data) {
  const result = await axios.post("/v1/test/submitTestAnswer", data);
  userStore.refresh();
  return result;
}

export function getLeaderBoard() {
  return axios.get("/v1/auth/leaderboard");
}

export function getProfile() {
  return axios.get("/v1/auth/profile");
}
export function answerSurvey(data) {
  return axios.post("/v1/survey/answer", data);
}
export function getSurvey() {
  return axios.get("/v1/survey");
}
export function getIsAnswer() {
  return axios.get("/v1/survey/isAnswer");
}

export function getRatings() {
  return axios.get("/v1/auth/ratings");
}

export function getMyReadings() {
  return axios.get("/v1/reading/getMyReadings");
}

export function getMyTasks() {
  return axios.get("/v1/task/getMyTasks");
}

export async function createComment(data) {
  const result = await axios.post("/v1/comment/createComment", data);
  userStore.refresh();
  return result;
}

export function getCategories() {
  return axios.get("/v1/category/getAll");
}

export function getDrafts() {
  return axios.get("/v1/book/drafts");
}

export function startChallenge(data) {
  return axios.post("/v1/book/start", data);
}

export function getBookEvaluate(bookId) {
  return axios.get("/v1/book/evaluate", { params: { bookId } });
}

export function getBookChallenge(id) {
  return axios.get("/v1/book/challenge", {
    params: { id },
  });
}
export function markAction(data) {
  return axios.post("/v1/book/markaction", data);
}
export function addBookReaction(data) {
  return axios.post("/v1/book/reaction", data);
}
export function getBookReaction(bookId, challengeId) {
  return axios.get("/v1/book/reaction", {
    params: {
      bookId,
      challengeId,
    },
  });
}
export function getBookChallenges(id) {
  return axios.get("/v1/book/challenges", {
    params: { id },
  });
}
export function answerBook(data) {
  return axios.post("/v1/book/answer", data);
}

export function getBook(id) {
  return axios.get("/v1/book", {
    params: {
      id,
    },
  });
}

export function getBookInfo(id) {
  return axios.get("/v1/book/info", {
    params: {
      id,
    },
  });
}

export function downloadQuestionTemplate() {
  return axios.get("/v1/upload/questionTemplate", {
    responseType: "blob",
  });
}

export function downloadSurveyTemplate() {
  return axios.get("/v1/upload/surveyTemplate", {
    responseType: "blob",
  });
}

export function deleteBook(id) {
  return axios.delete("/v1/book", {
    params: {
      id,
    },
  });
}

export function getClasses() {
  return axios.get("/v1/class");
}
export function getSurveys() {
  return axios.get("/v1/survey/all");
}

export function getSemesterStatis() {
  return axios.get("/v1/semester/statistics");
}

export function getFeedbacks() {
  return axios.get("/v1/book/feedbacks");
}

export function readReaction(data) {
  return axios.post("/v1/book/readReaction", data);
}
export function readFeedback(data) {
  return axios.post("/v1/book/readFeedback", data);
}

export function getUnreadReactions() {
  return axios.get("/v1/book/getUnreadReactions");
}
export function getUnreadFeedbacks() {
  return axios.get("/v1/book/getUnreadFeedbacks");
}
export function getStatics(id) {
  return axios.get("/v1/survey/statistics", { params: { id } });
}

export function downloadSemesterStastic() {
  return axios.get("/v1/upload/semesterStatistics", {
    responseType: "blob",
  });
}

export function downloadSurveyStastic(id) {
  return axios.get("/v1/upload/surveyStatistics", {
    params: {
      id,
    },
    responseType: "blob",
  });
}



export function getReaction(id) {
  return axios.get("/v1/book/reactionDetail", { params: { id } });
}
export function getReactions() {
  return axios.get("/v1/book/reactions");
}
export function getBooks(params) {
  return axios.get("/v1/book/all", {
    params,
  });
}
export function getAdminBooks(params) {
  return axios.get("/v1/book/adminAll", {
    params,
  });
}

export function joinCountry(data) {
  return axios.post("/v1/country/joinCountry", data);
}

export async function createIdea(data) {
  const result = await axios.post("/v1/idea/createIdea", data);
  userStore.refresh();
  return result;
}

export function getProposal(data) {
  return axios.post("/v1/proposal/getProposal", data);
}

export function getMyProposal(data) {
  return axios.post("/v1/proposal/getMyProposal", data);
}

export function saveProposal(data) {
  return axios.post("/v1/proposal/saveProposal", data);
}

export async function submitProposal(data) {
  const result = await axios.post("/v1/proposal/submitProposal", data);
  userStore.refresh();
  return result;
}

export function createProposal(data) {
  return axios.post("/v1/proposal/createProposal", data);
}

export function downloadMyProposal(data) {
  return axios.post("/v1/proposal/downloadMyProposal", data);
}

export function getEvaluationList(data) {
  return axios.post("/v1/evaluation/getEvaluationList", data);
}

export function addFeedBack(data) {
  return axios.post("/v1/book/feedback", data);
}

export function getSemesters() {
  return axios.get("/v1/semester/all");
}

export function getReal() {
  return axios.get("/v1/auth/realLeader");
}

// 进步排行榜
export function getProgress(id) {
  return axios.get("/v1/auth/progressLeader", { params: { id } });
}

export function getGrades() {
  return axios.get("/v1/book/grades");
}
export async function submitEvaluation(data) {
  const result = await axios.post("/v1/evaluation/submitEvaluation", data);
  userStore.refresh();
  return result;
}

export function modifyPassword(data) {
  return axios.post("/v1/auth/modifyPassword", data);
}

export function getClass() {
  return axios.get("/v1/class");
}

export function createUser(data) {
  return axios.post("/v1/auth/createUser", data);
}

export function getCurrentUser(data) {
  return axios.post("/v1/auth/getCurrentUser", data);
}

export function updateUserStatus(data) {
  return axios.post("/v1/auth/updateStatus", data);
}

export function getUserDataReq(data) {
  return axios.post("/v1/auth/getUserData", data);
}

export function getUserDataReadingReq(data) {
  return axios.post("/v1/auth/getUserDataReading", data);
}

export function getUserKnowledgeData(data) {
  return axios.post("/v1/auth/getUserKnowledgeData", data);
}

export function getProposalWritting(data) {
  return axios.post("/v1/auth/getProposalWrittingData", data);
}

export function getClassIssues(data) {
  return axios.post("/v1/country/getClassIssues", data);
}

export function updateIssueAvailable(data) {
  return axios.post("/v1/country/updateAvailable", data);
}

export function updateMaxMemberCount(data) {
  return axios.post("/v1/country/updateMaxMemberCount", data);
}

export function getUserDetail(data) {
  return axios.post("/v1/auth/getUserDetail", data);
}

export function getUserDetailReading(data) {
  return axios.post("/v1/auth/getUserDetailReading", data);
}

export function getChallenge() {
  return axios.get("/v1/strategy/getChallenge");
}

export function getStrategy() {
  return axios.get("/v1/strategy/getAll");
}

export function createPlan(data) {
  return axios.post("/v1/plan/createPlan", data);
}

export function getMyTask() {
  return axios.get("/v1/task/getMyTask");
}

export function createTask() {
  return axios.post("/v1/task/createTask");
}

export function createReadingTask() {
  return axios.post("/v1/reading/createReadingTask");
}

export function saveReadingLevel(data) {
  return axios.post("/v1/reading/saveReadingLevel", data);
}

export function submitSelfAssessment(data) {
  return axios.post("/v1/task/submitSelfAssessment", data);
}

export function submitSurvey(data) {
  return axios.post("/v1/task/submitSurvey", data);
}

export function submitReadingSurvey(data) {
  return axios.post("/v1/reading/submitSurvey", data);
}

export function discoverArticle(data) {
  return axios.post("/v1/task/discoveryArticle", data);
}

export function discoverReading(data) {
  return axios.post("/v1/reading/discoveryArticle", data);
}

export function submitReading(data) {
  return axios.post("/v1/reading/submitReading", data);
}

export function getTaskDetail(id) {
  return axios.get("/v1/task/getTaskDetail", {
    params: {
      taskId: id,
    },
  });
}

export function getReadingTaskDetail(id) {
  return axios.get("/v1/reading/getReadingTaskDetail", {
    params: {
      readingId: id,
    },
  });
}

export function selectArticle(data) {
  return axios.post("/v1/task/selectArticle", data);
}

export function selectReading(data) {
  return axios.post("/v1/reading/selectArticle", data);
}

export function updateTaskDetail(data) {
  return axios.post("/v1/task/updateTaskDetail", data);
}

export function updateReadingDetail(data) {
  return axios.post("/v1/reading/updateReadingDetail", data);
}

export function addStrategy(data) {
  return axios.post("/v1/task/addStrategy", data);
}

export function aiAskHelp(data) {
  return axios.post("/v1/task/aiAskHelp", data);
}

export function aiStrategyHelp(data) {
  return axios.post("/v1/task/aiStrategyHelp", data);
}

export function collectAiHelp(data) {
  return axios.post("/v1/task/collectAiHelp", data);
}

export function submitSelfReflection(data) {
  return axios.post("/v1/task/submitSelfReflection", data);
}

export async function submitReview(data) {
  const result = await axios.post("/v1/task/submitReview", data);
  userStore.refresh();
  return result;
}

export function boardGroup() {
  return axios.get("/v1/task/boardGroup");
}

export function getBoardList(id) {
  return axios.get("/v1/task/getBoardList", {
    params: {
      challengeId: id,
    },
  });
}

export function getBoardDetail(id) {
  return axios.get("/v1/task/getBoardDetail", {
    params: {
      reviewId: id,
    },
  });
}

export async function createLike(id) {
  const result = await axios.get("/v1/task/createLike", {
    params: {
      reviewId: id,
    },
  });
  userStore.refresh();
  return result;
}

export function submitComment(data) {
  return axios.post("/v1/task/submitComment", data);
}

export function submitExercises(data) {
  return axios.post("/v1/task/submitExercises", data);
}

export function submitBook(data) {
  return axios.post("/v1/book/add", data);
}

export async function submitReadingExercises(data) {
  const result = await axios.post("/v1/reading/submitExercises", data);
  userStore.refresh();
  return result;
}

export function countReadingTime(id) {
  return axios.get("/v1/task/countReadingTime", {
    params: {
      taskId: id,
    },
  });
}

export function countNewReadingTime(id) {
  return axios.get("/v1/reading/countReadingTime", {
    params: {
      readingId: id,
    },
  });
}

export function getGraphData() {
  return axios.get("/v1/task/getGraphData");
}

export function getPlanList() {
  return axios.get("/v1/plan/getPlanList");
}

export function generateExercises(taskId) {
  return axios.get("/v1/task/generateExercises", {
    params: {
      taskId,
    },
  });
}

export function generateReadingExercises(readingId) {
  return axios.get("/v1/reading/generateExercises", {
    params: {
      readingId,
    },
  });
}

export function generateQuestion(data) {
  return axios.post("/v1/book/generateQuestion", data);
}

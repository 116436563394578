import { observer } from "mobx-react";
import styles from "../../css/UserChallengeFinish.module.scss";
import titleImg from "../../img/title.png";
import rePlay from "../../img/replay.png";
import Stars from "../../components/stars";
import { useNavigate, useParams } from "react-router";
import { Input, message } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  getBook,
  getBookChallenge,
  getBookChallenges,
  addBookReaction,
  getBookEvaluate,
  startChallenge,
} from "../../utils/request";
import goldImg from "../../img/gold.png";
import silverImg from "../../img/silver.png";
import copperImg from "../../img/copper.png";
import { LayoutContext } from "../auth";

function UserChallengeFinish() {
  const [book, setBook] = useState({});
  const { id, challengeId } = useParams();
  const [rating, setRating] = useState(0);
  const [levelTxt, setLevelTxt] = useState("");
  const [levelImg, setLevelImg] = useState("");
  const [finishedNum, setFinishedNum] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [starNum, setStarNum] = useState(0);
  const [bookMark, setBookMark] = useState(0);
  const [bookVote, setBookVote] = useState(0);
  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);

  const getChallengeInfo = useCallback(() => {
    getBookChallenge(challengeId).then((res) => {
      setRating(res.data.rating);
      getLevel(res.data.incorrectAnswers);
    });
  }, [challengeId]);

  const getBookInfo = useCallback(() => {
    getBook(id).then((res) => {
      setBook(res.data);
    });
  }, [id]);

  const getEvaluate = useCallback(() => {
    getBookEvaluate(id).then((res) => {
      setBookMark(res.data.mark);
      setBookVote(res.data.vote);
    });
  }, [id]);
  const getChallenges = useCallback(() => {
    getBookChallenges(id).then((res) => {
      setFinishedNum(res.data.length);
    });
  }, [id]);

  useEffect(() => {
    getBookInfo();
    getChallengeInfo();
    getChallenges();
    getEvaluate();
    setHead({
      needMenu: false,
    });
  }, [setHead, getChallengeInfo, getBookInfo, getChallenges, getEvaluate]);

  function getLevel(incorrectCount) {
    if (incorrectCount === 0) {
      setLevelTxt("获得金色勋章");
      setLevelImg(goldImg);
    } else if (incorrectCount <= 2) {
      setLevelTxt("获得银色勋章");
      setLevelImg(silverImg);
    } else if (rating <= 4) {
      setLevelTxt("获得铜色勋章");
      setLevelImg(copperImg);
    }
  }

  function goChallengeAgain() {
    if (finishedNum === 3) return;
    startChallenge({ bookId: id }).then((res) => {
      navigate(`/user/challenge/${id}/${res.data.id}`, { replace: true });
    });
  }

  function onsubmit() {
    if (!title) {
      message.warning("请输入标题");
      return;
    }
    if (!content) {
      message.warning("请输入正文");
      return;
    }
    const data = {
      bookId: id,
      progressId: challengeId,
      title,
      content,
      mark: starNum,
    };
    addBookReaction(data).then((res) => {
      message.success("提交成功");
      navigate(`/user/reaction/${id}/${challengeId}`, { replace: true });
    });
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className={styles.contentLeft}>
          <div className={styles.contentTitle}>
            <img src={titleImg} alt="title"></img>
          </div>
          <div className={styles.contentBook}>
            <div className={styles.bookCover}>
              <img src={book.cover} alt="cover"></img>
            </div>
            <div className={styles.bookInfo}>
              <p className={styles.bookName}>{book.title}</p>
              <Stars value={bookMark} count={bookMark} disabled></Stars>
              <p className={styles.bookVote}>票数:{bookVote}</p>
            </div>
          </div>
          <div className={styles.contentBless}>
            <p>恭喜你！</p>
            <p>完成了《{book.title}》答题挑战</p>
            <p>
              你的分数是<span>{rating}</span>分 {levelTxt}
            </p>
          </div>
          <div
            className={`${styles.contentBtn} ${
              finishedNum === 3 ? styles.disabled : ""
            }`}
            onClick={goChallengeAgain}
          >
            <img src={rePlay} alt="rePlay"></img>
            <span>再挑战（{finishedNum}/3）</span>
          </div>
          <div className={styles.aura}></div>
          {levelImg && (
            <img className={styles.badge} src={levelImg} alt="level"></img>
          )}
          <div className={styles.fire}></div>
        </div>
        <div className={styles.contentRight}>
          <p className={styles.rightTitle}>
            写读后感可获得老师加分（满分20分）
          </p>
          <div className={styles.rightContent}>
            <Input
              className={styles.contentTitle}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={20}
              placeholder="不超过20字"
              prefix={<span>标题</span>}
            />
            <div className={styles.contentBody}>
              <span className={styles.contentLabel}>正文</span>
              <Input.TextArea
                onChange={(e) => setContent(e.target.value)}
                maxLength={1000}
                placeholder="正文不超过1000字"
              />
            </div>
            <div className={styles.mark}>
              <div className={styles.markRating}>
                <span className={styles.markRatingLabel}>对本书打分</span>
                <Stars value={starNum} onChange={(e) => setStarNum(e)}></Stars>
              </div>
              <div className={styles.submitBtn} onClick={onsubmit}>
                提交读后感
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default observer(UserChallengeFinish);
